import { useContext, useState} from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Container } from "@material-ui/core";
import * as mutations from "../../../operations/mutations";
import { useMutation } from "@apollo/client";
import CardHeader from "@mui/material/CardHeader";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { CountryContext, mainContext } from "../MainComponent";
import { useQuery } from "@apollo/client";
import * as queries from "../../../operations/queries";

function Login() {
  let loggedInToken = localStorage.getItem("loggedInCust");
  const storePath = window.location.pathname;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [islogin, setIslogin] = useState<any>(loggedInToken && JSON.parse(loggedInToken))
  const navigate = useNavigate();
  const { store } = useContext(CountryContext);
  const selectedStores =
  store && store !== "" ? store : storePath?.split("/")?.[1];
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [resError, setResError] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { quest_cart_id,/*  setCartCount, set_quest_cart_id */ } =
  useContext(mainContext);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  let [generateCustomerToken, { data: generateCustData }] = useMutation(
    mutations.loginMutation.LOGIN_MUTATION,
    {
      context: {
        headers: {
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const handleGenerateToken = async (event: any) => {
    event.preventDefault();
    const data = formData;
    try {
      const response = await generateCustomerToken({
        variables: data,
      });
      generateCustData = response?.data;
      localStorage.setItem(
        "loggedInCust",
        JSON.stringify(generateCustData?.generateCustomerToken?.token)
      );
      const isLogin = {
        isLogin: true,
        token: generateCustData?.generateCustomerToken?.token,
      };
      setResError("");
      setShowErrorAlert(false);
      navigate(`/${store}`, { state: isLogin });
      handleMergeCarts();
      custCartQueryDataRefetch()
    } catch (error: any) {
      if (error) {
        setResError(error?.message);
        // setResError(err);
        setShowErrorAlert(true);
        // generateCustError = err;
      }
    }
  };
  const { data: custQueryDataRes, refetch: custCartQueryDataRefetch } = useQuery(
    queries.customerQueries.GET_CUSTOMER_CART_QUERY,
    {
      context: {
        headers: {
          Authorization: islogin
            ? `Bearer ${islogin}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );
  let destinationCartId = custQueryDataRes?.customerCart?.id

  const [mergeCartsMutation, /* { data, loading, error } */] = useMutation(mutations.addToCartMutation.MERGE_CARTS,{
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store && store !== "" ? store : storePath?.split("/")?.[1],
      },
    },
  });
  const handleMergeCarts = async () => {
    try {
      /* const result =  */await mergeCartsMutation({
        variables: {
          cartId: quest_cart_id,
          destinationCartId: destinationCartId,
        },
      });
      const sessionStoreData = sessionStorage.getItem('storeAndCart');
      const currentsessionStore = sessionStoreData ? JSON.parse(sessionStoreData) : [];
      const filteredArray = currentsessionStore && currentsessionStore?.filter((object:any) => object.genStore !== selectedStores);
      sessionStorage.setItem("storeAndCart", JSON.stringify(filteredArray))
      } catch (error) {
      console.error('login page Merge Carts Error:', error);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="reg-content-wrap">
        <Container component="main" maxWidth="md">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={{ xs: 0, md: 8, lg: 8 }}
              justifyContent="center"
            >
              <Grid item lg={10} md={10} xs={12}>
                <Card
                  sx={{
                    maxWidth: "100%",
                    boxShadow: "none",
                    border: "1px solid #E0DEDE",
                    mb: 3,
                    p: 2,
                  }}
                >
                  <CardHeader
                    sx={{
                      backgroundColor: "#eee",
                      padding: "12px",
                      "& .MuiTypography-root": {
                        fontSize: "24px",
                        fontWeight: "700",
                        color: "#000",
                      },
                    }}
                    title={t("auth.login.login_text")}
                  />
                  <CardContent>
                    {showErrorAlert && (
                      <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {resError}
                      </Alert>
                    )}
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleGenerateToken}
                      autoComplete="off"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} className="input-field-wrap">
                          <TextField
                            placeholder={t("auth.login.email")}
                            required
                            fullWidth
                            id="email"
                            name="email"
                            value={formData.email}
                            autoComplete="off"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} className="input-field-wrap">
                          <TextField
                            placeholder={t("auth.login.pwd")}
                            required
                            fullWidth
                            id="password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            value={formData.password}
                            autoComplete="off"
                            onChange={handleChange}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={showPassword}
                                onChange={handleShowPassword}
                                name="showPassword"
                              />
                            }
                            label="Show Password"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            onClick={handleGenerateToken}
                            variant="contained"
                            disableElevation
                            sx={{
                              my: 2,
                              textTransform: "capitalize",
                              color: "#fff",
                              fontSize: "18px",
                              fontWeight: "500",
                              padding: "6px 34px",
                            }}
                          >
                            {t("auth.login.login")}
                          </Button>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Link
                            className="form-sub-text"
                            to={`/${store}/forgotpassword`}
                            style={{ color: "#1082C2" }}
                          >
                            {t("auth.login.forgot_password")}
                          </Link>
                        </Grid>
                        <Grid item xs={12}>
                          <span className="form-sub-text">
                            {t("auth.login.dont_have_acc")}{" "}
                            <Link
                              to={`/${store}/registration`}
                              style={{ color: "#1082C2" }}
                            >
                              {t("auth.login.create_new_acc")}
                            </Link>
                          </span>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <span className="form-sub-text">
                              {t("auth.login.continue_text")}{" "}
                              {/* <Link to="/" style={{ color: "#1082C2" }}> */}
                              {t("auth.login.cond_of_use")}
                              {/* </Link> */}
                              {t("auth.login.privacy_notice")}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </div>
  );
}
export default Login;
