import { Grid, Typography, Card, CardContent, CardMedia, Box, Button, CardActions } from '@mui/material';
import { Link, } from "react-router-dom";
import React, { useContext } from "react";
import { CountryContext } from "../MainComponent";
import { useQuery } from '@apollo/client';
import * as queries from "../../../operations/queries";
import Loader from "../Layout/loader";

const Blog = () => {
  const {  store } = useContext(CountryContext);
  // eslint-disable-next-line
  const { data: filterDataRes, loading: loading } = useQuery(queries.productQueries.BLOG_LIST,{
    context: {
      headers: {
         Connection: "keep-alive",
         store: store,
      },
    },
  });
  const blogData = filterDataRes?.getAllPosts;
  const blogItems = blogData && blogData?.items;  
  return (
      (loading) ? (
          <Loader />
      ) : (
          <Box sx={{ width: '100%', }}>
            <Box mb={4}>
              <img src={blogData?.banner} alt="Main Banner" style={{ width: '100%' }} />
            </Box>
            <Grid container spacing={2} style={{ display: 'flex', flexWrap: 'wrap', padding: "10px" }}>
              {blogItems?.map((article: any, index: any) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Card style={{ height: '100%' }}>
                    <CardMedia
                      component="img"
                      height="140"
                      image={article.thumbnail}
                      alt={article.title}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        {article.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {article.short_description}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Link to={`/${store}/blog/${article.link}`}>
                      <Button size="small">See More</Button>
                      </Link>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
      )
  );
};

export default Blog;
