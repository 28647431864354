import { useQuery } from "@apollo/client";
import * as queries from "../../operations/queries";
import { useContext, useEffect } from "react";
import { CountryContext } from "../../container/buyer/MainComponent";
import { useLocation } from "react-router-dom";
import "./payment.scss";
import { Helmet } from "react-helmet";

function Payment() {
  const { store } = useContext(CountryContext);
  const location = useLocation();
  const identifier = location?.pathname?.split("/");
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);
  const customHeader = {
    store: store,
    Connection: "keep-alive",
  };
  const { data: paymentData } = useQuery(queries.cmsQueries.GET_PAYMENT_PAGE, {
    variables: { identifier: identifier[identifier.length - 1] },
    context: {
      headers: customHeader,
    },
  });
  let paymentHtml = paymentData?.cmsPage?.content;
  const str = paymentHtml?.replaceAll("&lt;", "<");
  const finalStr = str?.replaceAll("&gt;", ">");
  return (
    <div className="PaymentWrapper">
      <Helmet>
        <title>{paymentData?.cmsPage?.meta_title !== "" ? paymentData?.cmsPage?.meta_title : `jebelz.com ${paymentData?.cmsPage?.title}`}</title>
        <meta name="description" content={paymentData?.cmsPage?.meta_description !== "" ? paymentData?.cmsPage?.meta_description : `jebelz.com ${paymentData?.cmsPage?.title}`} />
        <meta name="keywords" content={paymentData?.cmsPage?.meta_keyword !== "" ? paymentData?.cmsPage?.meta_keyword : `jebelz.com ${paymentData?.cmsPage?.title}`} />
        <meta name="DC.title" content={paymentData?.cmsPage?.meta_title} />
        <meta name="geo.region" content={(store === "in")?"IN-UP":"AE-DU"} />
        <meta name="geo.placename" content={(store === "in")?"Noida":"Dubai"} />
        <meta name="geo.position" content={(store === "in")?"28.58508;77.315262":"25.121614;55.377031"} />
        <meta name="ICBM" content={(store === "in")?"28.58508, 77.315262":"25.121614, 55.377031"} />
      </Helmet>
      <div dangerouslySetInnerHTML={{ __html: finalStr }}></div>
    </div>
  );
}

export default Payment;
