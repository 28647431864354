import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
import * as queries from "../../../operations/queries";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import PrintIcon from "@mui/icons-material/Print";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect,useRef } from 'react';
import { Container, Typography, Box, Stepper, Step, StepLabel, Paper } from '@mui/material';
import { styled } from '@mui/system';
//import { useReactToPrint } from 'react-to-print';

const ScrollContainer = styled('div')({
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  paddingBottom: '20px',
});

interface TrackingStep {
  label: string;
  completed: boolean;
  city: string;
  statusCode: string;
  time: number;
  hub: string;
}


const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
    },
  },
});

const TrackingOrder: React.FC = () => {
  const [trackingHistory, setTrackingHistory] = useState<TrackingStep[]>([]);
  const { t } = useTranslation();
  const classes = useStyles();
  const loggedInToken = localStorage.getItem("loggedInCust");
  // eslint-disable-next-line
  let subTotal: any;
  // let grandTotal: any;
  const { orderNumber } = useParams();
  const componentRef = useRef<HTMLDivElement>(null);
  //const handlePrint = useReactToPrint({
  //  content: () => componentRef.current,
  //});


  // eslint-disable-next-line @typescript-eslint/no-unused-vars 
  const [dense, setDense] = React.useState(false);
  const {
    data: orderDetailsRes,
  } = useQuery(queries.customerQueries.CUSTOMER_ORDER_DETAILS_QUERY, {
    variables: { orderNumber },
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
      },
    },
  });

  useEffect(() => {
    // Check if orderDetailsRes exists and customer data is present
    if (orderDetailsRes && orderDetailsRes.customer) {
      const orderTrackingHistory = orderDetailsRes.customer.orders.items[0]?.orderTrackingHistory[0]?.list;

      // Handle null orderTrackingHistory
      if (orderTrackingHistory) {
        const history: TrackingStep[] = orderTrackingHistory.map((item: any) => ({
          label: item?.note ?? '-',
          completed: item?.statusCode === 80, // Assuming 'delivered' status is the last step
          city: item?.cityName ?? '-',
          statusCode: item?.status ?? '-',
          time: item?.actionTime ?? '-',
        }));

        // Set tracking history state
        setTrackingHistory(history);
      } else {
        // Set tracking history to an empty list if orderTrackingHistory is null
        setTrackingHistory([]);
      }
    }
  }, [orderDetailsRes]);

  if (!orderDetailsRes || !orderDetailsRes.customer || !orderDetailsRes.customer.orders || !orderDetailsRes.customer.orders.items) {
    return <div>Loading...</div>;
  }
  const orderDetails = orderDetailsRes?.customer?.orders?.items[0];


  if (!orderDetails || !orderDetails.order_date) {
    return <div>Loading...</div>;
  }

  const extractDate = (dateTimeString: string) => {
    const datePart = dateTimeString.split(' ')[0]; // Extracting the date part
    return datePart;
  };

  orderDetails.items.forEach((data: any) => {
    subTotal +=
      data?.product_sale_price?.value * data?.quantity_ordered;
  });

  // grandTotal =
  // orderDetails?.total?.grand_total?.currency +
  // " " +
  // orderDetails?.total?.grand_total?.value;
  // const data = [
  //   { primaryText: "Subtotal", primaryValue: "AED 10.00" },
  //   { primaryText: "Cash on Delivery Fee", primaryValue: "AED 10.00" },
  //   { primaryText: "Shipping & Handling", primaryValue: "AED 10.00" },
  //   { primaryText: "Grand Total", primaryValue: grandTotal },
  // ];

  return (
    <div className="order-wrapper"  ref={componentRef}>
      <Container maxWidth={false}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
            spacing={2}
          >
             <Grid item xs={12}>
              <Card
                sx={{
                  minWidth: "100%",
                  boxShadow: "none",
                  background: "#F0F2F2",
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {orderDetails?.number}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {orderDetails?.status}
                      </Typography>
                      <Typography variant="h6" component="div" sx={{ fontSize: 14 }}>
                        {/* {orderDetails?.order_date} */}
                        {extractDate(orderDetails.order_date)}
                      </Typography>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        disableElevation
                        sx={{
                          my: 2,
                          textTransform: "capitalize",
                          color: "#fff",
                          fontSize: "18px",
                          fontWeight: "500",
                          padding: "6px 34px",
                        }}
                      >
                        {orderDetails?.status}
                      </Button>
                    </div>
                  </Box>
                </CardContent>
              </Card>
              <Card sx={{ minWidth: "100%", boxShadow: "none" }}>
                <CardContent sx={{ bgcolor: "#F9F9F9" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {t("buyer_dashboard.orders.item_order")}
                      </Typography>
                    </div>
                    <div>
                      <Button size="large" startIcon={<PrintIcon />}>
                        {t("buyer_dashboard.orders.print_btn")}
                      </Button>
                    </div>
                  </Box>
                  <Box>
                    <TableContainer className="order-tbl-container">
                      <Table
                        className={classes.table}
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead sx={{ height: 60 }}>
                          <TableRow>
                            <TableCell>
                              {t("buyer_dashboard.orders.prod_name")}
                            </TableCell>
                            <TableCell align="right">
                              {t("buyer_dashboard.orders.sku")}
                            </TableCell>
                            <TableCell align="right">
                              {t("buyer_dashboard.orders.price")}
                            </TableCell>
                            <TableCell align="right">
                              {t("buyer_dashboard.orders.qty")}
                            </TableCell>
                            <TableCell align="right">
                              {t("buyer_dashboard.orders.subtotal")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody sx={{ height: 100 }}>
                          {orderDetails?.items.map((row: any, index: any) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {row?.product_name}
                              </TableCell>
                              <TableCell align="right">
                                {row?.product_sku}
                              </TableCell>
                              <TableCell align="right">
                                {row?.product_sale_price?.currency}&nbsp;
                                {row?.product_sale_price?.value}
                              </TableCell>
                              <TableCell align="right">
                                {row?.quantity_ordered}
                              </TableCell>
                              <TableCell align="right">
                                {row?.product_sale_price?.currency}&nbsp;
                                {(row?.product_sale_price?.value * row?.quantity_ordered).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Container maxWidth={false} sx={{ mt: 1 }}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" component="div" gutterBottom>
                  Tracking History
                </Typography>
                <ScrollContainer>
                  <Stepper activeStep={trackingHistory.length - 1} alternativeLabel>
                    {trackingHistory.map((step, index) => (
                      <Step key={index} completed={step.completed}>
                        <StepLabel>
                          {step.label}
                          <br />
                          {new Date(step.time).toLocaleDateString('en-GB', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          }).replace(/\//g, '-')}
                          <br />
                          {new Date(step.time).toLocaleTimeString()}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </ScrollContainer>
              </Paper>
            </Container>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
export default TrackingOrder;
