import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { styled, TooltipProps, tooltipClasses } from "@mui/material";
// import Tooltip from "@mui/material/Tooltip";
import { CountryContext } from "../MainComponent";
import "./AllCategoriesMenu.css"; 
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from "react-i18next";

interface SubCategory {
  name: string;
  url_path: string;
}

interface CategoryBands {
  name: string;
  image: string;
  image_mobile: string;
  link: string;
}

interface Category {
  name: string;
  category_icon: string;
  url_path: string;
  children?: SubCategory[];
  category_brands: CategoryBands[];
}

// const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: "#fff",
//     color: "#000",
//     maxWidth: "100%",
//     fontSize: theme.typography.pxToRem(12),
//     borderRadius: 0,
//     marginTop: "0 !important",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     padding: "20px",
//   },
// }));

const AllCategoriesMenu = (props: { menuData: Category[] }) => {
  const { t } = useTranslation();
  const { store } = useContext(CountryContext);
  const [activeCategory, setActiveCategory] = useState<Category | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isMouseOverMenu, setIsMouseOverMenu] = useState(false);
  const [isPopperVisible, setIsPopperVisible] = useState(true);
  
  const handleCategoryMouseEnter = (category: Category) => {
    setActiveCategory(category);
    setIsMouseOverMenu(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOverMenu(false);
    setIsPopperVisible(true);
  };

  const handleSubCategoryClick = () => {
    setIsPopperVisible(false);
  };

  const handleCategoryClick = () => {
    setIsPopperVisible(false);
  };

  const handleAllCategoriesMouseEnter = () => {
    setIsPopperVisible(true);
  };

  useEffect(() => {
    setIsPopperVisible(true);
  }, []);

  return (
    <li id="header20" className={`left-category ${(store === "ae_ar" || store === "sa_ar" || store === "ar") ? "posiction rtl" : ""}`}>
      <div>
        <div className="menu20">
          <div
            className="row"
            style={{
              position: "absolute",
              marginTop: "-50px",
              color: "#fff",
            }}
          >
            <ul>
              <li className="l1">
                <Link
                  className="menuTitle a6dU7eb"
                  to="#"
                  style={{ color: "#fff", width: "120px", height: "45px" }}
                  onClick={() => setIsPopperVisible(true)}
                  onMouseEnter={handleAllCategoriesMouseEnter}
                >
                  <MenuIcon />
                  {t("main.all-categories")}
                </Link>
                {isPopperVisible && (
                  <div
                    id="radio_menu20Content"
                    className="menu20Content"
                    style={{
                      width: "1020px",
                      marginLeft: "10px",
                      height: "530px",
                    }}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div
                      className="row flex-container noMargin noPadding oHide"
                      style={{ width: "100%" }}
                    >
                      <div
                        className={`westSide col-s-12 col-m-12 ${(store === "ae_ar" || store === "sa_ar" || store === "ar") ? "displayFlex rtl" : ""}`}
                        style={{
                          width: "1000px",
                          height: "1000px",
                          marginLeft: 0,
                        }}
                      >
                        <div
                          className="category-scroll col-s-3 col-m-3"
                          style={{
                            overflowY: "scroll",
                            maxHeight: "500px",
                            flex: "1",
                            marginLeft: 0,
                            overflowX: "hidden",
                            maxWidth: "230px"
                          }}
                          onMouseEnter={() => setIsMouseOverMenu(true)}
                        >
                          <ul
                            className="full row l2"
                            style={{
                              margin: "0.5em 0 0 0em",
                              padding: "0px",
                            }}
                          >
                            {props.menuData.map((category, index) => (
                              <li
                                key={index}
                                onMouseEnter={() =>
                                  handleCategoryMouseEnter(category)
                                }
                              >
                                <div
                                  className={`col-s-12 col-l-3 title noMargin a5etf titleAllCatMenu ${category.url_path}`}
                                >
                                  <Link
                                    to={`/${store}/category/${category.url_path}.html`}
                                    onClick={handleCategoryClick}
                                    style={{ color: "#5C5A5A" }}
                                  >
                                    {
                                    (category?.category_icon && category?.category_icon !=="https://access.jebelz.com/media/")?
                                    <span className="menuIcon" style={{backgroundImage: `url(${category?.category_icon})`}} /> 
                                    : <span className="menuIcon" />
                                    }
                                    {category.name}
                                  </Link>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>

                        {activeCategory &&
                          activeCategory.children &&
                          activeCategory.children.length > 0 && (
                            <div
                              className="subcategory-container col-s-9 col-m-9 p-5"
                              style={{
                                display: 'flex',
                                flexDirection: "column",
                                color: "black",
                                marginTop: "20px"
                              }}
                            >
                              <div
                                className="subcategory-scroll col-s-8 col-l-12 flex-wrapper mt-lg-4 mt-6 content noMargin right-inner-hover"
                                style={{
                                  overflowY: "hidden",
                                  flex: 1,
                                }}
                                onMouseEnter={() => setIsMouseOverMenu(true)}
                              >
                                <div className="a5efd row s-hide m-hide l-hide">
                                  {activeCategory.name}
                                </div>
                                <ul className="l3">
                                  <div className="row" style={{minHeight: "300px"}}>
                                    {activeCategory.children.map(
                                      (subCategory, subIndex) => (
                                        <div
                                          key={subIndex}
                                          className="col-s-3 col-l-3 noMargin oHide"
                                        >
                                          <li className="col-s-12 a5elt">
                                            <Link
                                              to={`/${store}/category/${subCategory.url_path}.html`}
                                              style={{ color: "black" }}
                                              className="full block"
                                              onClick={handleSubCategoryClick}
                                            >
                                              {subCategory.name}
                                            </Link>
                                          </li>
                                        </div>
                                      )
                                    )}
                                  </div>
                                  <div className="row">
                                    <div
                                      className="brands-container col-s-12 col-l-12 mt-lg-4 flex-wrapper content"
                                      style={{
                                        paddingLeft: "20px",
                                        overflowY: "hidden",
                                        flexShrink: 0,
                                      }}
                                    >
                                      {(activeCategory?.category_brands.length > 0) ?
                                        <h2 style={{ color: "#0056b3", padding: "5px" }}>Top Brand</h2>
                                        : ""
                                      }
                                      {activeCategory?.category_brands.map(cb =>
                                        <>
                                          <Link to={`/${store}/category/${cb?.link}`} title={`${cb.name}`}>
                                            <img
                                              key={cb.name}
                                              src={cb.image}
                                              alt={`${cb.name}`}
                                              style={{
                                                width: "150px",
                                                marginRight: "8px",
                                              }}
                                            />
                                          </Link>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </ul>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
  );
};

export default AllCategoriesMenu;
