import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Grid from "@material-ui/core/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import GridUn from "@mui/material/Unstable_Grid2";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
// import NativeSelect from "@mui/material/NativeSelect";
import InputLabel from "@mui/material/InputLabel";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useQuery } from "@apollo/client";
import * as queries from "../../../operations/queries";
import theme from "../../../theme";
import Popover from "@mui/material/Popover";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import "./Domestic-machine.scss";
import { CountryContext } from "../MainComponent";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReactStars from "react-rating-star-with-type";
import Pagination from "@mui/material/Pagination";
import { Helmet } from "react-helmet";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Loader from "../Layout/loader";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// import dotenv1 from 'dotenv';

const filterIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M19 3H5C3.586 3 2.879 3 2.44 3.412C2 3.824 2 4.488 2 5.815V6.505C2 7.542 2 8.061 2.26 8.491C2.52 8.921 2.993 9.189 3.942 9.723L6.855 11.363C7.491 11.721 7.81 11.9 8.038 12.098C8.512 12.509 8.804 12.993 8.936 13.588C9 13.872 9 14.206 9 14.873V17.543C9 18.452 9 18.907 9.252 19.261C9.504 19.616 9.952 19.791 10.846 20.141C12.725 20.875 13.664 21.242 14.332 20.824C15 20.407 15 19.452 15 17.542V14.872C15 14.206 15 13.872 15.064 13.587C15.1896 13.0042 15.5059 12.4798 15.963 12.097C16.19 11.9 16.509 11.721 17.145 11.362L20.058 9.722C21.006 9.189 21.481 8.922 21.74 8.492C22 8.062 22 7.542 22 6.504V5.814C22 4.488 22 3.824 21.56 3.412C21.122 3 20.415 3 19 3Z"
      stroke="#118FA7"
      stroke-width="1.5"
    />
  </svg>
);

const responsive: any = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 10,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 10,
    slidesToSlide: 3,
  },
  laptop: {
    breakpoint: { max: 1200, min: 1024 },
    items: 6,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1,
  },
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function DomesticMachine({ onLoad, isCountry }: any) {
  let minFilterPrice: number = 0;
  let maxFilterPrice: number = 0;
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const [selectedMenufacturer, setSelectedMenufacturer] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedSorting, setSelectedSorting] = useState<any>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { country, store } = useContext(CountryContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [priceValue, setPriceValue] = useState<number[]>([
    minFilterPrice,
    maxFilterPrice,
  ]);
  const [fetchProd, setFetchPro] = useState(false);
  const [filteredPro, setFilteredPro] = useState<any>([]);
  const [selectedPage, setSelectedPage] = useState<any>();
  const [totalPage, setTotalPage] = useState<any>();
  const open = Boolean(anchorEl);
  const [sortOrder, setSortOrder] = useState<string | undefined>(undefined);
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setFetchPro(true)
    setPriceValue(newValue as number[]);
  };
  function valuetext(value: number) {
    return `${value}AED`;
  }
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [filteredPro]);

  const customHeader = {
    store: store,
    Connection: "keep-alive",
  };
  const url = location?.pathname?.split("/category");
  const urlKey = url[url.length - 1];
  const { data: categoryid, refetch: refatchId } = useQuery(
    queries.cartQueries.GET_CATEGORY_URL,
    {
      variables: { url: urlKey },
    }
  );

  useEffect(() => {
    refatchId();
  }, [urlKey, refatchId]);

  var category_id = categoryid?.urlResolver?.id?.toString();
  const { data: categoryProduct, refetch } = useQuery(
    queries.productQueries.SIMPLE_CATEGORY_PRODUCTLIST,
    {
      variables: { categoryId: category_id, currentPage: currentPage, sortOrder: sortOrder },
      context: {
        headers: customHeader,
      },
    }
  );

  const { data: catDetails, refetch: refetchCatDetails } = useQuery(
    queries.productQueries.GET_CATEGORY_DETAILS,
    {
      variables: { id: category_id },
      context: {
        headers: customHeader,
      },
    }
  );
  const catMetaData = catDetails?.category;
  useEffect(() => {
    /*const headElement = document.querySelector('head');
    const titleTag = headElement?.querySelector('title');
    const metatTitleTag = headElement?.querySelector('meta[name="title"]');
    const metatDcTitleTag = headElement?.querySelector('meta[name="DC.title"]');
    if(titleTag) {
      titleTag.innerHTML = catMetaData?.meta_title;
    }
    if(metatTitleTag) {
      metatTitleTag.setAttribute('content',catMetaData?.meta_title);
    }
    if(metatDcTitleTag) {
      metatDcTitleTag.setAttribute('content',catMetaData?.meta_title);
    }
    const metatKeywordsTag = headElement?.querySelector('meta[name="keywords"]');
    if(metatKeywordsTag) {
      document?.querySelector('head')?.querySelector('meta[name="keywords"]')?.setAttribute('data-react-helmet','true');
      document?.querySelector('head')?.querySelector('meta[name="keywords"]')?.setAttribute('content',"1111111: "+catMetaData?.meta_keywords);
    }
    
    const metatDescriptionTag = headElement?.querySelector('meta[name="description"]');
    if(metatDescriptionTag) {
      metatDescriptionTag.setAttribute('content',catMetaData?.meta_description);
    }*/
    
  },[catMetaData]);

  let breadcrumbs: any = [];

  if (catMetaData) {
    if (catMetaData.breadcrumbs && catMetaData.breadcrumbs.length > 0) {
      breadcrumbs = [
        <Link key="1" color="inherit" to="/">
          {t("inner_pages.detail_page.home")}
        </Link>,
        <Link key="2" color="inherit" to={`/${store}/category/${catMetaData.breadcrumbs[0].category_url_path}.html`}>
          {catMetaData.breadcrumbs[0].category_name}
        </Link>,
        <Link key="3" color="inherit" to={`/${store}/category/${catMetaData.breadcrumbs[0].category_url_path}/${catMetaData.url_key}.html`}>
          {catMetaData.name}
        </Link>
      ];
    } else {
      breadcrumbs = [
        <Link key="1" color="inherit" to="/">
          {t("inner_pages.detail_page.home")}
        </Link>,
        <Link key="2" color="inherit" to={`/${store}/category/${catMetaData.url_key}.html`}>
          {catMetaData.name}
        </Link>
      ];
    }
  }
  const { data: catRes } = useQuery(queries.homeQueries.GET_CATEGORY_SLIDER, {
    variables: { categoryId: category_id },
    context: {
      headers: customHeader,
    },
  });
  const catData = catRes?.getSubCategoriesData;
  useEffect(() => {
    setCurrentPage(1)
  }, [category_id])
  useEffect(() => {
    refetchCatDetails();
    refetch();
    // eslint-disable-next-line
  }, [category_id, refetch, currentPage]);
  let categoryProductList = categoryProduct?.products?.items;
  const filterAggregationProducts =
    categoryProduct && categoryProduct?.products?.aggregations;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDropdownOpen(!isDropdownOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = open ? "simple-popover" : undefined;

  const { data: filterDataRes, refetch: refetchProd } = useQuery(
    queries.productQueries.GET_PRODUCTS_FILTER,
    {
      variables: {
        categoryId: category_id,
        from: String(priceValue[0]),
        to:  String(priceValue[1]),
        // categoryId : '1780',
        manufacturerName: selectedMenufacturer,
        sorting: selectedSorting,
        pageSize: 48,
        sortby: {
          price: 'ASC'
        }
      },
    }
  );
  const filterProductItems = filterDataRes && filterDataRes?.products?.items;

  useEffect(() => {
    if (!fetchProd) {
      const currnetPages = categoryProduct?.products?.page_info?.current_page;
      const totalPages = categoryProduct?.products?.page_info?.total_pages;
      setFilteredPro(categoryProductList);
      setTotalPage(totalPages);
      if (currnetPages) {
        setCurrentPage(currnetPages);
        setSelectedPage(currnetPages);
      }
    } else {
      const currnetPages = filterDataRes?.products?.page_info?.current_page;
      const totalPages = filterDataRes?.products?.page_info?.total_pages;
      setFilteredPro(categoryProductList);
      setTotalPage(totalPages);
      if (currnetPages) {
        setCurrentPage(currnetPages);
        setSelectedPage(currnetPages);
      }

      setFilteredPro(filterProductItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProductItems, categoryProductList]);

  
  useEffect(() => {
    if (fetchProd) {
      refetchProd();
    }// eslint-disable-next-line
  }, [fetchProd]);

  filterAggregationProducts &&
    // eslint-disable-next-line array-callback-return
    filterAggregationProducts.map((data: any) => {
      if (data?.attribute_code === "price") {
        const minPriceProduct = data?.options.reduce(
          (min: any, currentProduct: any) => {
            return parseInt(currentProduct.value) < parseInt(min.value)
              ? currentProduct
              : min;
          },
          data?.options[0]
        );
        const maxPriceProduct = data?.options.reduce(
          (max: any, currentProduct: any) => {
            return parseInt(currentProduct.value) > parseInt(max.value)
              ? currentProduct
              : max;
          },
          data?.options[0]
        );
        maxFilterPrice = parseInt(maxPriceProduct?.value);
        minFilterPrice = parseInt(minPriceProduct?.value);
      }
    });
  useEffect(() => {
    setPriceValue([0, maxFilterPrice]);
  }, [minFilterPrice, maxFilterPrice]);

  function handleChipClick(item: any) {
    setFetchPro(true);
    const index = selectedMenufacturer.indexOf(item?.value);
    if (index === -1) {
      setSelectedMenufacturer([...selectedMenufacturer, item?.value]);
    } else {
      const a = selectedMenufacturer.filter(
        (data: any) => data !== item?.value
      );
      setSelectedMenufacturer(a);
    }
  }
  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    if (value === "RECMD") {
      setSortOrder(undefined);
    } else {
      setSortOrder(value);
    }
    refetch();
  };


  return (
    <>
      <Helmet>
        <title>{catMetaData?.meta_title}</title>
        <meta name="description" content={catMetaData?.meta_description} />
        <meta name="keywords" content={catMetaData?.meta_keywords} />
        <meta name="DC.title" content={catMetaData?.meta_title} />
        <meta name="geo.region" content={(store ==="in")?"IN-UP":"AE-DU"} />
        <meta name="geo.placename" content={(store ==="in")?"Noida":"Dubai"} />
        <meta name="geo.position" content={(store === "in")?"28.58508;77.315262":"25.121614;55.377031"} />
        <meta name="ICBM" content={(store === "in")?"28.58508, 77.315262":"25.121614, 55.377031"} />
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        className="breadcrumb-data"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <div className="main-content">
        <Box>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Card
                sx={{ maxWidth: "100%", boxShadow: 0 }}
                className="category-home-grid"
              >
                <CardContent>
                  {catData ? (
                    <>
                      <div style={{
                        direction: store === "ae_ar" || store === "sa_ar" || store === "ar" ? 'rtl' : 'ltr',
                        textAlign: store === "ae_ar" || store === "sa_ar" || store === "ar" ? 'right' : 'left'
                      }}>
                        <Carousel
                          className="category-home-grid-slider directionrtl"
                          responsive={responsive}
                          swipeable={true}
                          autoPlay={false}
                          centerMode={false}
                          arrows={false}
                          infinite={true}
                          ssr={true}
                          showDots={false}
                          slidesToSlide={1}
                          deviceType={""}
                          removeArrowOnDeviceType={["tablet", "mobile"]}
                        >
                          {catData.map((data: any, index: any) => {
                            return (
                              <div className="category-box">
                                <Link to={`/${store}/category/${data?.url_key}`}>
                                  <div className="category-box-img">
                                    <img
                                      className="img-cover"
                                      draggable={false}
                                      style={{ position: "relative" }}
                                      src={data.image}
                                      alt={data.name}
                                    />
                                  </div>
                                  <div className="category-box-title">
                                    <span>
                                      {data.name}{" "}
                                      {isSmallScreen
                                        ? data.image_mobile
                                        : data.image_mobile}
                                    </span>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                        </Carousel>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <div style={{
          direction: store === "ae_ar" || store === "sa_ar" || store === "ar" ? 'rtl' : 'ltr',
          textAlign: store === "ae_ar" || store === "sa_ar" || store === "ar" ? 'right' : 'left'
        }}>
          <Box sx={{ pt: 5 }}>
            <Card
              sx={{ maxWidth: "100%", boxShadow: 0 }}
              className="topsell-machine-grid"
            >
              <Box sx={{ minWidth: 120, px: 2 }}>
                { /* sorting dropdown */}
                <div className="sortby-btn">
                  <FormControl sx={{ m: .5, minWidth: 200, float: "right" }} size="small">
                    <InputLabel id="demo-select-small-label">{t("inner_pages.domestic_machine.sort_by")}</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={sortOrder === undefined ? "RECMD" : sortOrder}
                      label="Sort By"
                      onChange={handleChange}
                    >
                      <MenuItem value={"RECMD"}>{t("inner_pages.domestic_machine.recommended")}</MenuItem>
                      <MenuItem value={"ASC"}>{t("inner_pages.domestic_machine.price_low_to_h")}</MenuItem>
                      <MenuItem value={"DESC"}>{t("inner_pages.domestic_machine.price_high_to_low")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="filter-box filter-input-box">
                  <Box sx={{ minWidth: "100px" }}>
                    <Button
                      aria-describedby={id}
                      variant="contained"
                      onClick={handleClick}
                      sx={{
                        bgcolor: "#fff",
                        boxShadow: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                      }}
                      className="filter-btn"
                      endIcon={
                        isDropdownOpen ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      }
                    >
                      <span
                        style={{
                          marginRight: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {filterIcon}
                      </span>
                      <span className="filter-text">{t("inner_pages.domestic_machine.filter")}</span>
                    </Button>
                    <Popover
                      className="filter-popver"
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Card sx={{ minWidth: "100%", boxShadow: "none" }}>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={12} md={12}>
                              {filterAggregationProducts &&
                                filterAggregationProducts.map(
                                  (data: any) => {
                                    return data?.attribute_code ===
                                      "manufacturer" ? (
                                      <Accordion
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",

                                          boxShadow: "none",
                                        }}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          sx={{
                                            // border: "1px solid #66E5FF",
                                            // background: "#10CDF2",
                                            border: "1px solid #97a4a7",
                                            background: "#cad1d3",
                                          }}
                                        >
                                          <Typography>
                                            {t(
                                              "inner_pages.domestic_machine.menufacturer"
                                            )} 
                                            <span
                                            className="menufacturer-brand title"
                                             style={{marginLeft: "5px"}}>
                                            ({data?.options.length})</span>
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                          sx={{
                                            border: "1px solid #E5E3E3",
                                          }}
                                        >
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                              flexWrap: "wrap",
                                              gap: "10px",
                                            }}
                                          >
                                            {data?.options.map(
                                              (item: any) => {
                                                return (
                                                  <Chip
                                                    key={item?.value}
                                                    onClick={() =>
                                                      handleChipClick(item)
                                                    }
                                                    label={`${t(`brand.${item?.label}`).replace("brand.","")} (${item?.count})`}
                                                    className={`filter-chip ${selectedMenufacturer.includes(
                                                      item?.value
                                                    )
                                                      ? "active"
                                                      : "" 
                                                      }`}
                                                    component="a"
                                                    href="#"
                                                    clickable
                                                    variant="outlined"
                                                    sx={{
                                                      border:
                                                        "1px solid #EFEFEF",
                                                      background: "#FAFAFA",
                                                      px: 4,
                                                      borderRadius: "5px",
                                                    }}
                                                  />
                                                );
                                              }
                                            )}
                                          </Stack>
                                        </AccordionDetails>
                                      </Accordion>
                                    ) : (
                                      ""
                                    );
                                  }
                                )}
                            </Grid>
                            <Grid item xs={12} lg={12} md={12}>
                              {filterAggregationProducts &&
                                filterAggregationProducts.map(
                                  (data: any) => {
                                    return data?.attribute_code ===
                                      "price" ? (
                                      <Accordion
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "20px",
                                          boxShadow: "none",
                                        }}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          sx={{
                                            // border: "1px solid #66E5FF",
                                            // background: "#10CDF2",
                                            border: "1px solid #97a4a7",
                                            background: "#cad1d3",
                                          }}
                                        >
                                          <Typography>
                                            {t(
                                              "inner_pages.domestic_machine.price"
                                            )}
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails
                                          sx={{
                                            border: "1px solid #E5E3E3",
                                          }}
                                        >
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            sx={{ mb: 3 }}
                                          >
                                            {/* {data?.options.map((item:any) => { */}
                                            {/* return ( */}
                                            <>
                                              <Chip
                                                sx={{
                                                  border:
                                                    "1px solid #EFEFEF",
                                                  background: "#FAFAFA",
                                                  px: 2,
                                                  borderRadius: "5px",
                                                }}
                                                label={`${filterDataRes?.products
                                                  ?.aggregations &&
                                                  filterDataRes?.products
                                                    ?.aggregations[0]
                                                    ?.currency === undefined ? "AED" : 'AED'
                                                  } ${minFilterPrice}`}
                                                variant="outlined"
                                              />
                                              <Chip
                                                sx={{
                                                  border:
                                                    "1px solid #EFEFEF",
                                                  background: "#FAFAFA",
                                                  px: 2,
                                                  borderRadius: "5px",
                                                }}
                                                label={`${filterDataRes?.products
                                                  ?.aggregations &&
                                                  filterDataRes?.products
                                                    ?.aggregations[0]
                                                    ?.currency === undefined ? "AED" : 'AED'
                                                  } ${maxFilterPrice}`}
                                                variant="outlined"
                                              />
                                            </>
                                            {/* ); */}
                                            {/* })} */}
                                          </Stack>
                                          <Box sx={{ width: 230 }}>
                                            <Slider
                                              getAriaLabel={() =>
                                                "Price range"
                                              }
                                              value={priceValue}
                                              onChange={handleSliderChange}
                                              valueLabelDisplay="auto"
                                              getAriaValueText={valuetext}
                                              min={minFilterPrice}
                                              max={maxFilterPrice}
                                            />
                                          </Box>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent:
                                                "space-between",
                                              alignItems: "center",
                                            }}
                                          >
                                            <span className="directionrtl">
                                              {t(
                                                "inner_pages.domestic_machine.2365-products"
                                              )}
                                            </span>
                                          </Box>
                                        </AccordionDetails>
                                      </Accordion>
                                    ) : (
                                      ""
                                    );
                                  }
                                )}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Popover>
                  </Box>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={10} md={4} lg={3}>

                  </Grid>
                </Grid>
              </Box>
              <CardContent className="category_list">
                {filteredPro === undefined ?
                  (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Loader type="isSmallLoader" />
                    </Box>
                  ) :
                  filteredPro?.length > 0 ? (
                    <GridUn
                      container
                      rowSpacing={5}
                      columnSpacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 12 }}
                    >
                      {Array.from(Array(filteredPro?.length)).map((_, index) => (
                        <GridUn
                          xs={6}
                          sm={4}
                          md={3}
                          lg={2}
                          xl={2}
                          key={filteredPro[index].sku}
                        >
                          <Link
                            to={`/${store}/${filteredPro[index]?.url_key}.html`}
                          >
                            <Item
                              className="jeb-cust-slide"
                              sx={{ boxShadow: 0 }}
                            >
                              <Card
                                sx={{
                                  maxWidth: "100%",
                                  boxShadow: 0,
                                  border: "1px solid #e1e1e1",
                                }}
                              >
                                <div className="slider-img-box">
                                  <CardMedia
                                    component="img"
                                    alt={filteredPro[index]?.image?.__typenamel}
                                    height="100%"
                                    image={filteredPro[index]?.image?.small_url}
                                  />

                                  <div className="offer-main-box">
                                  </div>
                                </div>
                                <CardContent
                                  sx={{
                                    textAlign: "start",
                                    backgroundColor: "#fff",
                                  }}
                                >
                                  <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                    color="text.dark"
                                    className="top-sell-title"
                                  >
                                    {filteredPro[index].name}
                                  </Typography>
                                  {filteredPro[index].price_range?.minimum_price?.discount?.amount_off > 0 && (
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      color="text.secondary"
                                      component="del"
                                      className="top-actual_amount"
                                    >
                                      {filteredPro[index].price_range?.minimum_price?.regular_price?.currency}{" "}
                                      {filteredPro[index].price_range?.minimum_price?.regular_price?.value}
                                    </Typography>
                                  )}

                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    color="text.dark"
                                    className="top-sell-amount"
                                  >
                                    {filteredPro[index].price_range?.minimum_price?.final_price?.currency ? filteredPro[index].price_range?.minimum_price?.final_price?.currency: filteredPro[index].price_range?.minimum_price?.regular_price?.currency}{" "}
                                    {filteredPro[index].price_range?.minimum_price?.final_price?.value ? filteredPro[index].price_range?.minimum_price?.final_price?.value : filteredPro[index].price_range?.minimum_price?.regular_price?.value}
                                  </Typography>


                                  <Typography
                                    gutterBottom
                                    variant="h6"
                                    color="text.secondary"
                                    component="del"
                                    className="top-actual_amount"
                                  >
                                    {filteredPro[index].actual_amount}
                                  </Typography>
                                  {filteredPro?.configurable_options && (
                                    <div className="color-switch-list">
                                      {filteredPro?.configurable_options[0]
                                        ?.values?.length > 2
                                        ? filteredPro?.configurable_options[0]?.values?.map(
                                          // eslint-disable-next-line array-callback-return
                                          (item: any, index: any) => {
                                            const restCount =
                                              filteredPro
                                                ?.configurable_options[0]
                                                ?.values?.length - 4;
                                            if (index === 4) {
                                              return (
                                                <Button size="small">
                                                  <div className="color-switch">
                                                    +{restCount}
                                                  </div>
                                                </Button>
                                              );
                                            } else if (index < 4) {
                                              return (
                                                <Button size="small">
                                                  <div
                                                    className="color-switch"
                                                    style={{
                                                      backgroundColor:
                                                        item?.swatch_data
                                                          ?.value,
                                                    }}
                                                  ></div>
                                                </Button>
                                              );
                                            }
                                          }
                                        )
                                        : filteredPro?.configurable_options[0]?.values.map(
                                          (colors: any) => {
                                            return (
                                              <Button size="small">
                                                <div
                                                  className="color-switch"
                                                  style={{
                                                    backgroundColor:
                                                      colors?.swatch_data
                                                        ?.value,
                                                  }}
                                                ></div>
                                              </Button>
                                            );
                                          }
                                        )}
                                    </div>
                                  )}
                                  <div className="stars-block">
                                    {filteredPro?.rating_summary > 0 && (
                                      <ReactStars
                                        size={18}
                                        isHalf={true}
                                        activeColor="#DD8C27"
                                        value={filteredPro?.rating_summary}
                                        activeColors={[
                                          "#DD8C27",
                                          "#DD8C27",
                                          "#DD8C27",
                                          "#DD8C27",
                                          "#DD8C27",
                                        ]}
                                      />
                                    )}
                                    {filteredPro?.review_count > 0 && (
                                      <div>
                                        <span>
                                          {filteredPro?.review_count} Review
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                  <Typography
                                    gutterBottom
                                    variant="h6"
                                    color="text.dark"
                                    className="top-vat"
                                  >
                                    {country === "in"
                                      ? t("main.inclusive_of_gst")
                                      : t("main.inclusive_of_vat")}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Item>
                          </Link>
                        </GridUn>
                      ))}
                    </GridUn>
                  ) : (
                    <div>
                      {t("inner_pages.domestic_machine.no-product-found")}
                    </div>
                  )}
              </CardContent>
            </Card>
          </Box>
          {totalPage && (
            <Box sx={{ pt: 5 }}>
              <Stack spacing={2}>
                <Pagination
                  count={totalPage ? totalPage : 10}
                  shape="rounded"
                  onChange={handlePageChange}
                  defaultPage={selectedPage}
                />
              </Stack>
            </Box>
          )}
        </div>
        {/* )} */}
      </div>
    </>
  );
}
export default DomesticMachine;
