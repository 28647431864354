import React, { useContext, useState, useRef, useEffect } from "react";
import { BottomNavigation, BottomNavigationAction, Hidden } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import CategoryIcon from '@mui/icons-material/Category';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { CountryContext } from "../MainComponent";
// import * as queries from "../../../operations/queries";
// import { useQuery } from "@apollo/client";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { mainContext } from "../MainComponent";
// import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Badge from "@mui/material/Badge";
import AddCart from "./addcart";

// interface Category {
//   name: string;
//   url_path: string;
// }
const BottomNavbar = (props: any) => {
  const { t } = useTranslation();
  const loggedInToken = props.loggedInToken;
  const handleOpenUserMenu = props.handleOpenUserMenu;
  // const handleCloseUserMenu = props.handleCloseUserMenu;
  const loggedInString = props.loggedInString;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [activeCategory, setActiveCategory] = useState<Category | null>(null);
  const [value, setValue] = React.useState(0);
  const { country, store } = useContext(CountryContext);
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const addToCartRef = useRef<any>(null);

  const {
    cartData,
    cartCount,
    quest_cart_id,
    // setCartCount,
    // set_quest_cart_id,
  } = useContext(mainContext);


  // const customHeader = {
  //   store: store,
  //   Connection: "keep-alive",
  // };

  const menuId = "bottom-search-account-menu";

  // const parentId = 2;

  // const {
  //   // loading: menuLoader,
  //   data: menuRes,
  //   // refetch,
  // } = useQuery(queries.headerQueries.GET_MENUS, {
  //   variables: { parentId },
  //   context: {
  //     headers: customHeader,
  //   },
  // });

  // const menuData: Category[] = menuRes?.categories?.items || [];

  // const handleCategoryMouseEnter = (category: Category) => {
  //   setActiveCategory(category);
  // };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  useEffect(() => {

  }, [cartData]);

  return (
    <>
      <Hidden mdUp>
        <div className="category-menu">
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 1000 }}
          >

            <BottomNavigationAction
              // label="Home"
              icon={
                <Link to={`/${country}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                  <HomeIcon />
                  <h5 style={{ fontWeight: "700", fontSize: "11px", color: "#687e94" }}>Home</h5>
                </Link>
              }
            />

            <BottomNavigationAction
              // label="Categories"
              icon={
                <Link to={`/${country}/all-categories`} style={{ color: 'inherit', textDecoration: 'none' }}>
                  <CategoryIcon />
                  <h5 style={{ fontWeight: "700", fontSize: "11px", color: "#687e94" }}>Categories</h5>
                </Link>
              }
            />
            <BottomNavigationAction icon={
              <div className="icon-btn-account-login" style={{ marginTop: "9px" }}>
                {loggedInToken !== "" && loggedInToken !== null ? (
                  <IconButton
                    size="medium"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleOpenUserMenu}
                    color="inherit"
                  >
                    <Box
                      sx={{
                        display: {

                          height: "32px",
                          width: "32px",
                          backgroundColor: "#f1e8c6",
                          borderRadius: "100%",
                          // padding: "14px",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid black",

                        },
                      }}
                    >
                      <h5
                        style={{
                          fontSize: "13px",
                          fontWeight: "700",
                          color: "black",
                          justifyContent: "center",
                          display: "flex",
                          marginTop: "9px",
                        }}
                      >
                        {loggedInString}

                      </h5>
                    </Box>
                  </IconButton>
                ) : (
                  <>

                    <Link to={`/${store}/login`} style={{ color: 'inherit', textDecoration: 'none' }}>
                      <AccountCircleIcon />
                      <h5 style={{ fontWeight: "700", fontSize: "11px", color: "#687e94" }}>Account</h5>
                    </Link>

                  </>
                )}
              </div>
            } />

            <BottomNavigationAction icon={
              <div className="icon-btn-cart" style={{ marginTop: "9px" }}>
                <IconButton
                  size="medium"
                  ref={setAnchor}
                  onClick={() => setOpenPopup((val) => !val)}
                  // onClick={handleAddToCart}
                  aria-label="shopping cart"
                  color="inherit"
                  type="button"
                  sx={{ p: 0 }}
                >
                  <Box
                    sx={{
                      display: {
                        xs: "none",
                        md: "flex",
                        fontSize: "16px",
                        marginRight: "6px",
                      },
                      fontSize: "15px",
                      marginRight: "5px",
                    }}
                  >
                    {t("main.cart")}
                  </Box>
                  <Badge badgeContent={cartCount} color="primary">
                    
                    <ShoppingCartIcon />
                  </Badge>

                </IconButton>
                <h5 style={{ fontWeight: "700", fontSize: "11px", color: "#687e94" }}>Cart</h5>
                <div ref={addToCartRef}>
                  <AddCart
                    isOpenPopup={openPopup}
                    isAnchor={anchor}
                    handleClosePopup={handleClosePopup}
                    isCartId={quest_cart_id}
                  />
                </div>
              </div>
            } />
          </BottomNavigation>
        </div>
      </Hidden>
    </>
  );
};

export default BottomNavbar;
