import React, {  useContext } from "react";
import {  Grid, Typography, Card, CardContent,  Box, } from '@mui/material';
import GridUn from "@mui/material/Unstable_Grid2";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CountryContext } from "../MainComponent";
import { useQuery } from "@apollo/client";
import * as queries from "../../../operations/queries";
// import { useMediaQuery } from "@mui/material";
// import theme from "../../../theme";


function BrandList() {
    const { t } = useTranslation();
    const {  store } =
        useContext(CountryContext);
    // const initialColumnsToShow = 36;
    // const [showAllBrands, _setShowAllBrands] = useState(false);

    const customHeader = {
        store: store,
        Connection: "keep-alive",
    };

    /*const {
        loading: brandLoader,
        data: brandRes,
        refetch: refetchBrand,
    } = useQuery(queries.homeQueries.GET_BRAND, {
        context: {
            headers: customHeader,
        },
    });*/
    // const manufacturer = "Hario";
    const { data: brandRes } = useQuery(
        queries.productQueries.GET_BRANDS_LIST,
        {
            context: {
                headers: customHeader,
            }
        }
    );
    console.log("brand data",brandRes?.customAttributeMetadata);
    const brandData = brandRes?.customAttributeMetadata?.items[0]?.attribute_options ?? [];
    // const columnsToShow = showAllBrands ? brandData.length : initialColumnsToShow;
    // const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <div>
            <Box sx={{ pt: { lg: 5, md: 4, xs: 3 } }}>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <Card
                            sx={{ maxWidth: "100%", boxShadow: 0 }}
                            className="brand-grid"
                        >
                            <div className="section-title">
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="span"
                                    color="text.dark"
                                >
                                    {t("main.brand-title")}
                                </Typography>
                            </div>
                            <CardContent sx={{ backgroundColor: "#fafafa" }}>
                                <Box sx={{ flexGrow: 1, p: 2 }}>
                                    <GridUn
                                        container
                                        spacing={2}
                                        sx={{
                                            "--Grid-borderWidth": "1px",
                                            borderTop: "var(--Grid-borderWidth) solid",
                                            borderLeft: "var(--Grid-borderWidth) solid",
                                            borderColor: "divider",
                                            "& > div": {
                                                borderRight: "var(--Grid-borderWidth) solid",
                                                borderBottom: "var(--Grid-borderWidth) solid",
                                                borderColor: "divider",
                                            },
                                        }}
                                    >
                                        {brandData.map(
                                            (
                                                brand: {
                                                    label: any;
                                                    link: any;
                                                    option_label_en: any;
                                                    swatch_image: string | undefined;
                                                    image_mobile: string | undefined;
                                                    image: string | undefined;
                                                    name: string | undefined;
                                                },
                                                index: React.Key | null | undefined
                                            ) => (
                                                <GridUn
                                                    key={index}
                                                    {...{ xs: 4, sm: 2, md: 1.5, lg: 1 }}
                                                    minHeight={110}
                                                >
                                                    <div className="brand-img-box">
                                                        <Link to={`/${store}/brand/${brand?.option_label_en.toLowerCase().replaceAll(" ","-")}.html`}>
                                                            
                                                            {
                                                                (brand?.swatch_image !=="") ?
                                                                <>
                                                                    <img
                                                                        src={brand?.swatch_image}
                                                                        alt={brand?.name}
                                                                        style={{
                                                                            height: "100%",
                                                                            width: "100%",
                                                                            objectFit: "contain",
                                                                        }}
                                                                    />
                                                                </>
                                                                : `${brand.label}`
                                                            }
                                                        </Link>
                                                        {/*
                                                        {brand.link ? (
                                                            <Link to={`/${store}/category/${brand.link}`}>
                                                                <img
                                                                    src={isSmallScreen ? brand.image_mobile : brand.image}
                                                                    alt={brand?.name}
                                                                    style={{
                                                                        height: "100%",
                                                                        width: "100%",
                                                                        objectFit: "contain",
                                                                    }}
                                                                />
                                                            </Link>
                                                        ) : (
                                                            <img
                                                                src={isSmallScreen ? brand.image_mobile : brand.image}
                                                                alt={brand?.name}
                                                                style={{
                                                                    height: "100%",
                                                                    width: "100%",
                                                                    objectFit: "contain",
                                                                }}
                                                            />
                                                        )}*/}
                                                    </div>
                                                </GridUn>
                                            )
                                        )}
                                    </GridUn>
                                </Box>
                                {/* {brandData.length > initialColumnsToShow && (
                                    <Button
                                        sx={{ mt: 2, textTransform: "none" }}
                                        variant="outlined"
                                        onClick={() => setShowAllBrands(!showAllBrands)}
                                    >
                                        {showAllBrands
                                            ? t("main.view-less-brand")
                                            : t("main.view-all-brand")}
                                    </Button>
                                )} */}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default BrandList
