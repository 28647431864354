// PostpayWidget.js

import React, { useContext, useEffect } from "react";
import "./postPayStip.scss"
import { mainContext } from "../../MainComponent";
import { useTranslation } from "react-i18next";



const PostpayWidget = (props) => {
  const { store } = useContext(mainContext);
  const { t } = useTranslation();
  const storePath = window.location.pathname;
  const selectedStores =
    store && store !== "" ? store : storePath?.split("/")?.[1];
  const amt = props.amt * 100;
  useEffect(() => {
    window.postpayAsyncInit = function () {
      // eslint-disable-next-line no-undef
      postpay.init({
        merchantId: "id_048687d696514ba891f22af9aaaf7dac",
        sandbox: true,
        theme: "light",
        locale: `${selectedStores === "ae_en" ? "en" : "ar"}`,
      });
    };

    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cdn.postpay.io/v1/js/postpay.js";
    document.head.appendChild(script);

    return () => {
      // Cleanup if needed
      document.head.removeChild(script);
    };
    // eslint-disable-next-line
  }, []);
  const dataLocale = selectedStores === 'ae_en' || selectedStores === 'ae_er';

  return (
    <>
      {dataLocale && props.page === 'checkout' ?

        <div class="postpay-widget"
          data-type="payment-summary"
          data-environment="prod"
          data-amount={amt}
          data-currency="AED"
          data-num-instalments={3}
          data-country="AE"
          data-hide-if-invalid="{selector}"
          data-locale={`${selectedStores === "ae_en" ? "en" : "ar"}`}>
        </div>
        :
        <div
          class="postpay-widget"
          data-type="product"
          data-information-icon="false"
          data-amount={amt}
          data-currency="AED"
        >
          <div id="postpay-custom-widget">
            <span id="postpay-clickable-text">
              <span id="postpay-custom-widget-numInstalment">{3}&nbsp;</span>
              &nbsp;{t("inner_pages.postpayStrip.intrFree")}&nbsp;
            </span>
            {t("inner_pages.postpayStrip.payments")}&nbsp;
            <span id="postpay-custom-widget-amount">{amt}&nbsp;</span>
          </div>
        </div>
      }
    </>
  );
};

export default PostpayWidget;
