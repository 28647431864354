import { ReactNode, useState, useContext } from "react";
import Header from "./Layout/header";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./Layout/footer";
import "./buyer-gen.scss";
import { useEffect } from "react";
import { CountryContext } from "./MainComponent";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/client";
import * as queries from "../../operations/queries";

interface MainContentProps {
  children: ReactNode;
  countryCall: (store: any, val: any, load: any) => void;
  isCartId: string;
}
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiCardContent-root": {
      //  background-color: '#fafafa'
      "& .MuiPaper-root": {
        backgroundColor: "#ffffff",
      },
      "& .MuiCardContent-root:last-child": {
        paddingBottom: "0px",
      },
    },
  },
}));

function Layout(
  { isCartId, children, countryCall }: MainContentProps,
  count: any
) {
  const classes = useStyles();
  const { country, setCountry } = useContext(CountryContext);
  const [store, setStore] = useState("");
  const [onLoad, setOnLoad] = useState("");

  const handleCountry = (store: any, val: any, load: any) => {
    setStore(store);
    setOnLoad(load);
    setCountry(val);
  };
  const storePath = window.location.pathname;
  const baseStore = store !== "" ? store : storePath?.split("/")?.[1];
  const customHeader = {
    store: store && store !== "" ? store : storePath?.split("/")?.[1],
    Connection: "keep-alive",
  };
  const {
    /* loading: metaLoading, */
    data: metaRes,
    /* refetch: refetchMeta, */
  } = useQuery(queries.homeQueries.GET_HOME_META, {
    context: {
      headers: customHeader,
    },
  });

  useEffect(() => {
    countryCall(store, country, onLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, country, onLoad]);

  const handlefcountry = (val: any) => {
    setCountry(val);
    setStore(val);
  };
  return (
    <div className={classes.root}>
      <Helmet>
        <title>{metaRes?.cmsPage?.meta_title}</title>
        <meta name="description" content={metaRes?.cmsPage?.meta_description} />
        <meta name="keywords" content={metaRes?.cmsPage?.meta_keyword} />
        <meta name="DC.title" content={metaRes?.cmsPage?.meta_title} />
        <meta name="geo.region" content={(baseStore === "in")?"IN-UP":`AE-DU`} />
        <meta name="geo.placename" content={(baseStore === "in")?"Noida":"Dubai"} />
        <meta name="geo.position" content={(baseStore === "in")?"28.58508;77.315262":"25.121614;55.377031"} />
        <meta name="ICBM" content={(baseStore === "in")?"28.58508, 77.315262":"25.121614, 55.377031"} />
      </Helmet>
      <div className="app">
        <header className="header">
          <Header
            isFCountry={country}
            store={store}
            count={count}
            isCartId={isCartId}
          />
        </header>
        <div className="content-wrapper">
          <main className="main-content">{children}</main>
        </div>
        <footer className="footer">
          <Footer
            getCountry={handleCountry}
            callback={handlefcountry}
            store={store}
          />
        </footer>
      </div>
    </div>
  );
}

export default Layout;
